import Trial from './Trial';
import Firebase from './Firebase';
import { doc, updateDoc, getDoc, setDoc, getFirestore,  collection } from "firebase/firestore";
import {getFunctions, httpsCallable} from "firebase/functions";


class Account {
    static email;
    static username;
    static trialIds;
    static admin;
    static patientsRecruited;
    static sites = [];
    static trials = [];
    static set = false;
    static log = [];

    static async setValues(docSnap){
        Account.trialIds = docSnap.get("trials");
        Account.email = docSnap.get("email");
        Account.username = docSnap.get("user name");
        Account.sites = docSnap.get("sites");
        Account.admin = docSnap.get("admin");
        Account.patientsRecruited = docSnap.get("patients recruited");
        Account.trials = [];
        for (var i = 0 ; i < Account.trialIds.length; i ++){
            const trialRef = doc(Firebase.db, "trials", Account.trialIds[i]);
            const trialSnap = await getDoc(trialRef);
            let trial = new Trial(trialSnap);
            trial.id = Account.trialIds[i];
            Account.trials.push(trial);
        }  
        Account.log = docSnap.get("log");
    }

    static sendEmail(patientId, trial){
        const functions = getFunctions();
        const sendemail = httpsCallable(functions, 'sendemail');  
        sendemail({ subject: "Patient Randomized", body: "Patient: " + patientId + " randomized into " + trial + " by " + Account.username + " (" + Account.email + ")", recipient: "cedrictomlinson@hotmail.com"})
        .then((result) => {
            // Read result of the Cloud Function.
            /** @type {any} */
            const data = result.result;
            
        });
    }

    static async updatePatientsRecruited(trialId){
        Account.patientsRecruited[trialId] = Account.patientsRecruited[trialId] + 1;
        const userRef = doc(Firebase.db, "users", Account.email);
        await updateDoc(userRef, {
            "patients recruited": Account.patientsRecruited
        });
    }

    static async updateLog(trialName, trialId, patientId, site, time, 
        variable1, variable1Name, variable2, variable2Name, variable3, variable3Name, variable4, variable4Name){
        const map = {
            "trial id": trialId,
            "patient id": patientId,
            "site": site,
            "time": time,
            "trial name": trialName
        };
        if (variable1 != undefined){
            map["variable1"] = variable1;
        }
        if (variable2 != undefined){
            map["variable2"] = variable2;
        }
        if (variable3 != undefined){
            map["variable3"] = variable3;
        }
        if (variable4 != undefined){
            map["variable4"] = variable4;
        }
        if (variable1Name != undefined){
            map["variable1 name"] = variable1Name;
        }
        if (variable2Name != undefined){
            map["variable2 name"] = variable2Name;
        }
        if (variable3Name != undefined){
            map["variable3 name"] = variable3Name;
        }
        if (variable4Name != undefined){
            map["variable4 name"] = variable4Name;
        }
        Account.log.push(map);
        const userRef = doc(Firebase.db, "users", Account.email);
        await updateDoc(userRef, {
            "log": Account.log
        });
        Account.log = (await getDoc(userRef)).get("log");
    }

    static async update(){
        const docRef = doc(Firebase.db, "users", Account.email);
        const docSnap = await getDoc(docRef);
        await Account.setValues(docSnap);
    }

    toString() {
        return this.name + ', ' + this.email;
    }

    constructor(docSnap){
        this.trialIds = docSnap.get("trials");
        this.email = docSnap.get("email");
        this.username = docSnap.get("user name");
        this.sites = docSnap.get("sites");
        this.admin = docSnap.get("admin");
    }
}


export default Account;