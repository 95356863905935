import '../App.css';
import Account from '../Account';
import Trial from '../Trial';

import React, { useEffect, useState } from "react";
import CoordinatorComponent from './CoordinatorComponent';
import Firebase from '../Firebase';
import {runTransaction, doc, getDoc, updateDoc, setDoc, getFirestore,  collection } from "firebase/firestore";
import CheckComponent from './CheckComponent';
import RemoveAndSaveComponent from './RemoveAndSaveComponent';
import SiteComponent from './SiteComponent';
import LoadingComponent from './LoadingComponent';
import add from '../imgs/add.svg'


function ManageTrialComponent({setHeader}) {
  
  const [trialOn, setTrialOn] = useState(0);
  
  const [accountOn, setAccountOn] = useState(-1);
  const [accounts, setAccounts] = useState([]);
  const [coordChecks, setCoordChecks] = useState([]);
  const [accountsSet, setAccountsSet] = useState(false);
  
  const [siteOn, setSiteOn] = useState(-1);
  const [siteChecks, setSiteChecks] = useState([]);
  const [sitesSet, setSitesSet] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading...");
  const [started, setStarted] = useState(false);
  
  function trialOptions(){
    var optionArray = [];
    optionArray.push(<option hidden disabled value selected>Select a Trial</option>)
    for (var i = 0 ; i < Account.trials.length; i ++){
      if (Account.admin[Account.trialIds[i]]){
        optionArray.push(<option value={i}> {Account.trials[i].name} </option>)
      }
      else{
        optionArray.push(<option disabled value={i}> {Account.trials[i].name} </option>)
      }
    }
    return optionArray;
  }
    
  async function getAccounts(trial){
    var array = [];
    for (var i = 0 ; i < trial.researchCoordinators.length; i ++){
      const docRef = doc(Firebase.db, "users", trial.researchCoordinators[i]);
      const docSnap = await getDoc(docRef);
      var theirAccount = new Account(docSnap);
      array.push(theirAccount);
    }
    return array;
  }

  function assignedSitesComponents(){
    return Account.trials[trialOn].sites.map((val, ind) =>{
      const index = ind;
      return <CheckComponent name={val} checked={siteChecks[ind]} onClickFunction={() => checkSiteBox(index)}/>
    });
  }

  function checkSiteBox(index){
    var newValues = siteChecks.map((val, ind) => {
      if (ind === index) {
        return !val;
      } else {
        return val;
      }
    })
    setSiteChecks(newValues);
  }

  function coordinatorComponents(){
    var optionArray = [];
    for (var i = 0 ; i < accounts.length; i ++){
      const theirAccount = accounts[i];
      const index = i;
      optionArray.push(
        <div>
          <CoordinatorComponent arrow = {true} open={accountOn == i} account={theirAccount} onClickFunction={() => {
            if (index === accountOn){
              setAccountOn(-1);
            } else{
              setCoordinator(index, trialOn, accounts)
            }}}/>
          <div className='transition' style={{height: accountOn == i?'fit-content':"0px"}}> 
            <RemoveAndSaveComponent type = "coordinator" account={accounts[accountOn]} removeFunction={() => removeCoordinator()} saveFunction={() => saveCoordinatorChanges()}/>
            <div className='checkbox-div'> Their Active Sites</div>
            <div className='white-background'>
              {assignedSitesComponents()}
            </div>
          </div>
        </div>
      );
    }
    return optionArray;
  }

  function setCoordinator(coordVal, trialVal, accountsArray){
    setAccounts(accountsArray);
    setAccountOn(coordVal);
    if (coordVal == -1){
      return
    }
    if (coordVal >= accountsArray.length){
      console.log("returned");
      return;
    }
    var trial = Account.trials[trialVal];
    var account = accountsArray[coordVal];
    var array = [];
    console.log("account is " + account);
    for (var i = 0; i < trial.sites.length; i ++){
      const check = account.sites[trial.id].includes(trial.sites[i]);
      array.push(check);
    }
    setSiteChecks(array);
    setAccountsSet(true);
  }

  function removeCoordinator(){
    setAccountsSet(false);
    setLoadingText("Saving...");
    Trial.removeCoordinator(Account.trialIds[trialOn], accounts[accountOn].email).then(() => {
      Account.update().then(() => {
        setAccountOn(-1);
        setTrial(trialOn);
        setLoadingText("Loading...");
      })}
    );
  }

  async function saveCoordinatorChanges(){
    setAccountsSet(false);
    setSitesSet(false);
    setLoadingText("Saving...");
    var map = {};
    var newSites = [];
    for (var i = 0; i < Account.trials[trialOn].sites.length; i ++){
      if (siteChecks[i]){
        newSites.push(Account.trials[trialOn].sites[i]);
      }
    }
    map["sites." + Account.trials[trialOn].id] = newSites;
    const userRef = doc(Firebase.db, "users", accounts[accountOn].email);
    await updateDoc(userRef, map).then(() => {
      Account.update().then(() => {
        const acc = getAccounts(Account.trials[trialOn]);
        acc.then(result => {
          setCoordinator(accountOn, trialOn, result);
          const newChecks = getCoordChecks(siteOn, trialOn);
          newChecks.then(result2 => {
            setCoordChecks(result2);
            setAccountsSet(true);
            setSitesSet(true);
            setLoadingText("Loading...");
          })
        })
      })
    })    
  }

  async function saveSiteChanges(){
    setSitesSet(false);
    setAccountsSet(false);
    setLoadingText("Saving...");
    var trial = Account.trials[trialOn];
    var siteString = trial.sites[siteOn];
    await doSiteChanges(trial, siteString).then(() => {
      Account.update().then(() => {
        const newChecks = getCoordChecks(siteOn, trialOn);
        newChecks.then(result => {
          setCoordChecks(result);
          const acc = getAccounts(Account.trials[trialOn]);
          acc.then(result2 => {
            setCoordinator(accountOn, trialOn, result2);
            setAccountsSet(true);
            setSitesSet(true);
            setLoadingText("Loading...");  
          })})
      })
    });
  }

  async function doSiteChanges(trial, siteString){
    for (var i = 0 ; i < trial.researchCoordinators.length; i++){
      const userRef = doc(Firebase.db, "users", trial.researchCoordinators[i]);
      const checked = coordChecks[i];
      try {
        await runTransaction(Firebase.db, async (transaction) => {
          const transactionSnapshot = await transaction.get(userRef);
          if (!transactionSnapshot.exists()) {
            throw "Document does not exist!";
          }
          const sitesMap = transactionSnapshot.get("sites");
          const sites = sitesMap[trial.id];
          const newSites = [];
          var hit = false;
          for (var k = 0; k < sites.length; k++){
            const site = sites[k];
            if (site === siteString){
              console.log("same for " + trial.researchCoordinators[i]);
              if (checked){
                console.log("checked " + trial.researchCoordinators[i]);
                newSites.push(site);
              }
              hit = true;
            }
            else{
              newSites.push(site);
            }
          }
          if (!hit && checked){
            newSites.push(siteString);
          }
          console.log("coord : " + trial.researchCoordinators[i]);
          console.log("new sites: " + newSites);
          sitesMap[trial.id] = newSites;
          transaction.update(userRef, {"sites": sitesMap});
        });
        console.log("Transaction successfully committed!");
      } catch (e) {
        console.log("Transaction failed: ", e);
      }
    }  
  }
  
  async function setTrial(value){
    setStarted(true);
    setLoadingText("Loading...")
    setAccountsSet(false);
    setSitesSet(false);
    setTrialOn(value);
    var trial = Account.trials[value];
    setHeader(trial.nameShorthand);
    const acc = getAccounts(trial);
    acc.then(result => {
      setCoordinator(-1, value, result);
      setSiteOn(-1);
      setAccountsSet(true);
      setSitesSet(true);
    })
  }

  function assignedCoordinatorsComponents(){
    return Account.trials[trialOn].researchCoordinators.map((val, ind) =>{
      const index = ind;
      return <CheckComponent name={val} checked={coordChecks[ind]} onClickFunction={() => checkCoordBox(index)}/>
    });
  }

  function checkCoordBox(index){
    var newValues = coordChecks.map((val, ind) => {
      if (ind === index) {
        return !val;
      } else {
        return val;
      }
    })
    setCoordChecks(newValues);
  }

  function siteComponents(){
    var optionArray = [];
    for (var i = 0 ; i < Account.trials[trialOn].sites.length; i ++){
      var site = Account.trials[trialOn].sites[i]
      var siteShorthand = Account.trials[trialOn].sitesShorthand[i]
      const index = i;
      optionArray.push(<div>
        <SiteComponent arrow = {true} open={siteOn === i} site={site} siteShorthand = {siteShorthand} onClickFunction={() => {
          if (index === siteOn){
            setSite(-1);
          } else{
            setSite(index);
          }
          }}/>
        <div className='transition' style={{height: siteOn == i?'fit-content':"0px"}}> 
          <RemoveAndSaveComponent type = "site" account={accounts[accountOn]} removeFunction={() => removeSite()} saveFunction={() => saveSiteChanges()}/>
           <div className='checkbox-div'>Assigned Coordinators</div>
           <div className='white-background'>
           {assignedCoordinatorsComponents()}
           </div>
        </div>
      </div>);
    }
    return optionArray;
  };

  function setSite(value){
    if (value == -1){
      setSiteOn(value);
      return;
    }
    if (value >= Account.trials[trialOn].sites.length){
      return;
    }
    setSitesSet(false);
    const newChecks = getCoordChecks(value,  trialOn);
    newChecks.then(result => {
      setCoordChecks(result);
      setSiteOn(value);
      setSitesSet(true);
    })
  }

  async function getCoordChecks(siteVal, trialVal){
    var trial = Account.trials[trialVal];
    var siteOnString = trial.sites[siteVal];
    var array = [];
    for (var i = 0 ; i < trial.researchCoordinators.length; i ++){
      const docRef = doc(Firebase.db, "users", trial.researchCoordinators[i]);
      const docSnap = await getDoc(docRef);
      var theirAccount = new Account(docSnap);
      array.push(theirAccount.sites[Account.trials[trialVal].id].includes(siteOnString))
    }
    return array;
  }

  
  function removeSite(){
    setLoadingText("Saving...");
    setSitesSet(false);
    const trial = Account.trials[trialOn];
    Trial.removeSite(trial.id, trial.sites[siteOn]);
    
    /*Trial.removeCoordinator(Account.trialIds[trialOn], accounts[accountOn].email).then(() => {
      Account.update().then(() => {
        setAccountOn(0);
        setTrial(trialOn);
      })}
    );*/

    setLoadingText("Loading...");
  }

    
  return (

    <div className='manage-trial-div' id='manage-trial-div'>
      <div className='description-div'>
          <div className='description-div-text1'>
            Manage Trial Coordinators and Trial Sites
          </div>
          <div className='description-div-text2'>
          Add or remove Trial Coordinators from Sites, as well as adding new Sites and Coordinators to your Trial. You can only Manage trials where you are an Admin.
          </div>
      </div>
      <div>
      <div className='trial-label-div'>Trial</div>
      <select style={{color: started?'white':'#ffffffaf'}} className="classic" id='trial-select' onChange={event => {setTrial(event.target.value)}}>
        {trialOptions()}
      </select>
      </div>

    {started && ((!sitesSet || !accountsSet)? (
      <div className='loading-parent-manage-trials'>
        <LoadingComponent text={loadingText}/>
      </div>
      ):
      <div className='coords-sites-div'>
        <div className='manage-coords-div'>
          <div className='manage-trial-header'>
            Coordinators
          </div>
          <div className='coordinator-div'>
            {coordinatorComponents()}
          </div>
          <div className='add-coord-div'>
            <img className='add-img' src={add}/>
            <div className='add-text'>Add Coordinator</div>
          </div>
          <div className='padded-div' style={{height: "10px"}}/>
        </div>
        <div className='manage-sites-div'>
        <div className='manage-trial-header'>
            Sites
          </div>
          <div className='coordinator-div'>
            {siteComponents()}
          </div>
          <div className='add-coord-div'>
            <img className='add-img' src={add}/>
            <div className='add-text'>Add Site</div>
          </div>
          <div className='padded-div' style={{height: "10px"}}/>
        </div>
      </div>)}

    </div>
)
}

export default ManageTrialComponent;