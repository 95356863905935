import {runTransaction, doc, getDoc, setDoc, getFirestore,  collection, getDocs } from "firebase/firestore";
import React from "react";
import Firebase from "./Firebase";
import Account from "./Account";
import { serverTimestamp } from "firebase/firestore";
import { set } from "firebase/database";

import { getStorage, ref, getDownloadURL } from "firebase/storage";


class Trial {

    id;
    
    constructor (docSnap) {
        this.name = docSnap.get("name");
        this.nameShorthand = docSnap.get("name shorthand");
        this.patientsPerSite = docSnap.get("patients per site");
        this.totalPatients = docSnap.get("total patients");
        this.researchCoordinators = docSnap.get("research coordinators");
        this.sitesFull = docSnap.get("sites");
        this.sitesShorthandFull = docSnap.get("sites shorthand");
        this.description = docSnap.get("description");
        const logoName = docSnap.get("logo");
        if (logoName !== undefined){
          const storage = getStorage();
          getDownloadURL(ref(storage, logoName))
          .then((url) => {
            this.logoURL = url;
          })
          .catch((error) => {
            console.log(error);
          });
        }
        this.sites = this.sitesFull;
        this.sitesShorthand = this.sitesShorthandFull;
        this.sites = this.sitesFull.filter(n => n != null);
        this.sitesShorthand = this.sitesShorthandFull.filter(n => n != null);
        this.trialHeadEmail = docSnap.get("trial head email");
        this.variable1 = docSnap.get("variable 1");
        this.variable1Array = docSnap.get("variable 1 array");
        this.variable2 = docSnap.get("variable 2");
        this.variable2Array = docSnap.get("variable 2 array");
        this.variable3 = docSnap.get("variable 3");
        this.variable3Array = docSnap.get("variable 3 array");
        this.variable4 = docSnap.get("variable 4");
        this.variable4Array = docSnap.get("variable 4 array");
    };

    async addPatient(variables, email){
        const trialRef = doc(Firebase.db, "trials", this.id);
        try {
            await runTransaction(Firebase.db, async (transaction) => {
              const trialSnapshot = await transaction.get(trialRef);
              if (!trialSnapshot.exists()) {
                throw "Document does not exist!";
              }
              var indexName = "index";
              var tableName = "table";
              for (var k = 1; k < variables.length; k++){
                if (variables[k] === "default"){
                  break;
                }
                indexName = indexName + " " + variables[k];
                tableName = tableName + " " + variables[k];
              }
              console.log(indexName);
              console.log(tableName);
              var nextIndex = trialSnapshot.get(indexName);
              var tableDoc = doc(Firebase.db, "trials/" + this.id + "/" + tableName, "value " + nextIndex);
              var tableSnapshot = await transaction.get(tableDoc);
              var allocated = tableSnapshot.get("allocated");
              while (allocated){
                nextIndex = nextIndex + 1;
                tableDoc = doc(Firebase.db, "trials/" + this.id + "/" + tableName, "value " + nextIndex);
                tableSnapshot = await transaction.get(tableDoc);
                allocated = tableSnapshot.get("allocated");
              }
              this.patientsPerSite = trialSnapshot.get("patients per site");
              this.patientsPerSite[variables[1]] = this.patientsPerSite[variables[1]] + 1;
              this.totalPatients = trialSnapshot.get("total patients") + 1;
              const patientId = this.nameShorthand.replaceAll(" ", "-") + "-" + this.totalPatients;
              transaction.update(trialRef, {
                [indexName]: (nextIndex + 1),
                "patients per site": this.patientsPerSite,
                "total patients": this.totalPatients
              });
              
              transaction.update(tableDoc, {
                "allocated": true,
                "randomized by": Account.email,
                "date": serverTimestamp(),
                "patient id": patientId
              })
              
              
              var value = tableSnapshot.get("arm");
              console.log("the arm is "  + value);
            });
            const patientId = this.nameShorthand.replaceAll(" ", "-") + "-" + this.totalPatients;
            console.log("Transaction successfully committed!");
            await Account.updateLog(this.name, this.id, patientId, this.sites[variables[1]], new Date(), 
              this.variable1!=undefined?this.variable1Array[variables[2]]:undefined,
              this.variable1,
              this.variable2!=undefined?this.variable2Array[variables[3]]:undefined,
              this.variable2,
              this.variable3!=undefined?this.variable3Array[variables[4]]:undefined,
              this.variable3,
              this.variable4!=undefined?this.variable4Array[variables[5]]:undefined,
              this.variable4,
              
            );  
            await Account.updatePatientsRecruited(this.id);
            Account.sendEmail(patientId, this.name);
            return patientId;
          } catch (e) {
            console.log("Transaction failed: ", e);
            return "failed";
        }
        
    }

    static async removeCoordinator(trialId, email){
      const trialRef = doc(Firebase.db, "trials", trialId);
      const userRef = doc(Firebase.db, "users", email);
      try {
        await runTransaction(Firebase.db, async (transaction) => {
              const trialSnapshot = await transaction.get(trialRef);
              const userSnapshot = await transaction.get(userRef);
              if (!trialSnapshot.exists()) {
                throw "Trial does not exist!";
              }
              var coords = trialSnapshot.get("research coordinators");
              const index = coords.indexOf(email);
              if (index != -1){
                coords.splice(index, 1);
              }
              transaction.update(trialRef, {"research coordinators": coords});
              if (!userSnapshot.exists()){
                return;
              }
              var sites = userSnapshot.get("sites");
              var admin = userSnapshot.get("admin");
              var trials = userSnapshot.get("trials");
              var newAdmin = {};
              var newSites = {};
              for (var key in admin){
                if (key != trialId){
                  newAdmin[key] = admin[key];
                }
              }
              for (var key in sites){
                if (key != trialId){
                  newSites[key] = sites[key];
                }
              }
              const ind = trials.indexOf(trialId);
              if (ind != -1){
                trials.splice(ind, 1);
              }
              transaction.update(userRef, {
                "sites": newSites,
                "admin": newAdmin,
                "trials": trials
              });
        });
        console.log("Transaction successfully committed!");
      } 
      catch (e) {
        console.log("Transaction failed: ", e);
      }
      
    }

    static async removeSite(trialId, site){
      
      
    }

    
    toString() {
        return this.name + "(" + this.id + ")";
    }
}


export default Trial;